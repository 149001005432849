export default {
  data() {
    return {
      that: this,
      isAdmin:false,
      isWechat:navigator.userAgent.toLowerCase().match(/MicroMessenger/i)=="micromessenger",
      webinfo:{
        webLogo:''
      },
      area:1,
      apiUrl: process.env.VUE_APP_BASE_API,
      cdnUrl: process.env.VUE_APP_BASE_CDN,
      jiazheng_service_type: [],
      jiazheng_workstatus: [],
      sys_yes_no: [],
      jiazheng_order_condition: [],
      jiazheng_ayi_education: [],
      jiazheng_ayi_certificate: [],
      jiazheng_ayi_experience: [],
      jiazheng_ayi_visa: [],
      jiazheng_ayi_tag: [],
      cityArr2: [],
      cityArr3: [],
    };
  },
  filters: {
    filterAge: function (birthday) {
      // 出生时间 毫秒
      var birthDayTime = new Date(birthday).getTime();
      // 当前时间 毫秒
      var nowTime = new Date().getTime();
      // 一年毫秒数(365 * 86400000 = 31536000000)
      return Math.ceil((nowTime - birthDayTime) / 31536000000);
    },
    filterArea3: function (val, that) {
      // console.log(this)
      // console.log(111111111,val,that.cityArr3)
      let areaInfo = that.cityArr3.filter((v) => v.areaId == val);
      if (areaInfo.length > 0) {
        return areaInfo[0].areaName;
      } else {
        return;
      }
    },
  },
  
  beforeMount() {
    let webinfo = localStorage.getItem('webinfo')
    let {area,token,isAdmin} = this.$route.query
    if (isAdmin) {
      this.isAdmin = true;
      localStorage.setItem('isAdmin',true)
    }else{
      this.isAdmin =isAdmin= !!localStorage.getItem('isAdmin')
    }
    if (area) {
      localStorage.setItem('area',area)
    }else{
      area = localStorage.getItem('area')?localStorage.getItem('area'):1
    }

    if (token) {
      localStorage.setItem('token',atob(token))
    }

    this.area=area;
    if (!webinfo) {
      this.request({
        url:  "/system/config/info",
        method: "get",
      }).then(res => {
        this.webinfo = res.data
        localStorage.setItem('webinfo',JSON.stringify(res.data))
        console.log(res);
      }) 
    }else{
      this.webinfo=JSON.parse(webinfo)
    }

    // this.getDicts('jiazheng_workstatus');
    // this.getDicts('sys_yes_no');
    // this.getDicts('jiazheng_order_condition');
    // this.getDicts('jiazheng_ayi_education');
    // this.getDicts('jiazheng_ayi_visa');
    // if(this.area==1){
    //   this.getDicts('jiazheng_ayi_tag');
    //   this.getDicts('jiazheng_ayi_experience');
    //   this.getDicts('jiazheng_ayi_certificate');
    //   this.getDicts('jiazheng_service_type');
    // }else{
    //   this.getDicts('jiazheng_ayi_tag1');
    //   this.getDicts('jiazheng_ayi_experience1');
    //   this.getDicts('jiazheng_ayi_certificate1');
    //   this.getDicts('jiazheng_service_type1');
    // }

    this.request({
      url:  '/jiazheng/jiazhengArea2/list',
      method: 'get',
      params: {status: 1,pageSize: 3000}
    }).then((response) => {
      this.cityArr2 = response.data;
    });
    this.request({
      url: '/jiazheng/area/areaList',
      method: 'get',
      params: {status: 1,pageSize: 3000}
    }).then((response) => {
      this.cityArr = response.data;
      this.cityArr3 = response.data;
    });
  },
  methods: {
    formatArea: function (val) {
      // console.log(val)
      let areaInfo = this.cityArr.filter((v) => v.areaId == val);
      // console.log(val,areaInfo,that.cityArr)
      if (areaInfo.length > 0) {
        return areaInfo[0].areaName;
      } else {
        return;
      }
    },

    formatArea2: function (val) {
      // console.log(this)
      let areaInfo = this.cityArr2.filter((v) => v.area2Id == val);
      // console.log(val,areaInfo,that.cityArr)
      if (areaInfo.length > 0) {
        return areaInfo[0].areaName.replace('省','');
      } else {
        return;
      }
    },
    // 服务内容字典翻译
    idsFormat(ids, dictType) {
      ids = ids.split(',');
      let data = [];
      ids.map((item) => {
        data.push(this.selectDictLabel(this[dictType], item));
      });
      return data;
    },
    selectDictLabel(datas, value) {
      var actions = [];
      Object.keys(datas).map((key) => {
        if (datas[key].key == '' + value) {
          actions.push(datas[key].value);
          return false;
        }
      });
      return actions.join('');
    },
    
    dictFormat(dictType, value) {
      var actions = [];
      Object.keys(this[dictType]).map((key) => {
        if (this[dictType][key].key == '' + value) {
          actions.push(this[dictType][key].value);
          return false;
        }
      });
      return actions.join('');
    },
    getDicts: function (dictType) {
      return this.request({
        url:  '/system/config/dict/data/GetDictData',
        method: 'get',
        params: {
          dictType: dictType,
          defaultValue: '',
          status: 1,
        },
      }).then((res) => {
        console.log(res);
        this[dictType.replace('1','')] = res.data.values.map((v) => {
          v.text = v.value;
          v.id = v.key;
          // v.dot = true
          // v.className="seviceType"
          return v;
        });
      });
    },
    getAllDicts: function (dictType='jiazheng_workstatus,sys_yes_no,jiazheng_ayi_education,jiazheng_ayi_visa') {
      if (this.area==1) {
        dictType += ',jiazheng_ayi_tag,jiazheng_ayi_experience,jiazheng_ayi_certificate,jiazheng_service_type';
      } else if (this.area==2) {
        dictType += ',jiazheng_ayi_tag1,jiazheng_ayi_experience1,jiazheng_ayi_certificate1,jiazheng_service_type1';
      }
      return this.request({
        url:  '/system/config/dict/data/GetAllDicts',
        method: 'get',
        params: {
          dictType: dictType,
          defaultValue: '',
          status: 1,
        },
      }).then((res) => {
        dictType.split(',').map(v=>{
          this[v.replace('1', '')]=res.data[v].map((v1)=>{
            v1.text = v1.value;
            v1.id = v1.key;
            return v1;
          })
        })
      });
    },
  },
};
