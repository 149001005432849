<!-- eslint-disable vue/valid-v-model -->
<template>
  <div class="home">
    <van-nav-bar title="标题">
      <template #left>
        <van-icon name="apps-o" size="24" @click="setData('showMenu', true)" />
      </template>
      <template #title>
        <h1 class="logo">
          <div class="m-back" v-if="webinfo.webLogo">
            <van-image :src="cdnUrl + '/' + webinfo.webLogo" />
          </div>
          <div class="mulan">JIAZHENGMAO</div>
        </h1>
      </template>
      <template #right v-if="isAdmin">
        <van-icon name="search" size="24" @click="showSearch=!showSearch" />
      </template>
    </van-nav-bar>
    <van-search v-show="showSearch" v-model="queryParams.keyword" placeholder="请输入搜索关键词" />
    <h3 class="option" v-if="title">{{ title }}</h3>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list v-model="loading" class="people-list" :finished="finished" finished-text="没有更多了" @load="load">
        <div v-for="item in list" :key="item.ayiId" class="item">
          <router-link
            :to="{ path: 'detail', query: { uuid: item.uuid, area: area, activeSeviceTypeIndex: activeSeviceTypeIndex, activeCityIndex: activeCityIndex } }">
            <img :src="cdnUrl + '/' + item.avatar" alt="">
            <p class="name" style="font-weight:bold;">
              {{ idsFormat(item.serviceTypeId, 'jiazheng_service_type').join(' ') }} - {{ item.name }}</p>
            <!-- <p class="description"
              style="overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; ">
              {{ item.description }}</p> -->
            <p class="detail">{{ item.dob | filterAge }}岁 | {{ formatArea2(item.ayiCityId) }} |
              {{ dictFormat('jiazheng_ayi_experience', item.ayiExperienceId) }} |
              {{ dictFormat('jiazheng_ayi_education', item.ayiEducationId) }}</p>
          </router-link>
        </div>
      </van-list>
    </van-pull-refresh>
    <van-popup v-model:show="showMenu" position="left" :style="{ height: '100%', width: '80%' }">
      <van-row class="opt">
        <van-col span="24">
          <span style="margin-left:5px;">
            <van-icon name="cross" size="24" @click="setData('showMenu', false)" />
          </span>
          <!-- <van-button v-if="menuType==1"
                      type="primary"
                      size="small">城市</van-button>
          <van-button v-if="menuType==2"
                      type="primary"
                      size="small">类型</van-button> -->
          <span></span>
          <van-button type="danger" style="float:right;margin-top:0.4rem;margin-right:1rem;" size="small"
            @click="reset()">重置</van-button>
        </van-col>
      </van-row>
      <van-row>
        <van-col span="24">
          <van-tree-select class="treeSelect" :items="citys" :max="1" :active-id.sync="activeSeviceTypeIndex"
            :main-active-index.sync="activeCityIndex" />
        </van-col>
        <!-- <van-col span="24"
                 v-else-if="menuType==2">
          <van-tree-select class="treeSelect"
                           :items="seviceTypes"
                           :max="1"
                           :active-id.sync="queryParams.serviceTypeId"
                           :main-active-index.sync="activeSeviceTypeIndex" />
        </van-col> -->
      </van-row>
    </van-popup>
  </div>
</template>
<script>
import jiazheng from "@/mixins/jiazheng";
export default {
  mixins: [jiazheng],
  data: function () {
    return {
      title: "全部",
      showMenu: false,
      showSearch:false,
      init: true,
      activeCityIndex: "",
      activeSeviceTypeIndex: "",
      loading: false,
      refreshing: false,
      finished: false,
      webinfo: {},
      queryParams: {
        cityIds: "",
        serviceTypeId: "",
        pageNum: 1,
        pageSize: 10,
        keyword:''
      },
      citys: [],
      list: [],
    };
  },
  watch: {
    "activeCityIndex": function (val) {
      console.log(val, this.citys);
      if (val !== "" || val == "0") {
        this.queryParams.cityIds = this.citys[val].areaId;
        this.$router.push({ path: "/index", query: { activeCityIndex: this.activeCityIndex, activeSeviceTypeIndex: this.activeSeviceTypeIndex } })
        if (this.activeSeviceTypeIndex && this.citys[val].areaName) {
          // console.log(this['jiazheng_service_type'][this.activeSeviceTypeIndex])
          this.title = this.citys[val].areaName + ' — ' + this['jiazheng_service_type'][this['jiazheng_service_type'].findIndex(v => v.key == this.activeSeviceTypeIndex)].value
        } else if (this.activeSeviceTypeIndex) {
          this.title = this['jiazheng_service_type'][this['jiazheng_service_type'].findIndex(v => v.key == this.activeSeviceTypeIndex)].value
        } else if (this.citys[val].areaName) {
          this.title = this.citys[val].areaName
        }
        this.showMenu = false
      }
      if(!this.loading){
        this.load(1)
      }
    },
    "activeSeviceTypeIndex": function (val) {
      console.log(val, this['jiazheng_service_type']);
      if (val !== "" || val == "0") {
        this.queryParams.serviceTypeId = val
        this.$router.push({ path: "/index", query: { activeCityIndex: this.activeCityIndex, activeSeviceTypeIndex: this.activeSeviceTypeIndex, area: this.area } })
        if ((this.activeCityIndex != "" || this.activeCityIndex == "0") && this.citys[this.activeCityIndex].areaName) {
          this.title = this.citys[this.activeCityIndex].areaName + ' — ' + this['jiazheng_service_type'][this['jiazheng_service_type'].findIndex(v => v.key == val)].value
        } else {
          this.title = this['jiazheng_service_type'][this['jiazheng_service_type'].findIndex(v => v.key == val)].value
        }
        this.showMenu = false
      }
      if(!this.loading){
        this.load(1)
      }
    },
    "queryParams.keyword": function () {
      this.load(1);
    },
  },
  created() {
    var that = this;
    let isAdmin = localStorage.getItem('isAdmin')
    if (isAdmin) {
      this.isAdmin = true;
    }
    var city = [{
      text: '全部',
      id: '',
      areaId: '',
      areaName: '',
      parentId: ""
    }];
    // console.log(this);
    // this.showMenu = true;
    let params = {};
    // console.log(this.area, 111111111111)
    if (this.area == 1) {
      params = {
        isHaiWai: 1,
        status: 1
      };
    } else if (this.area == 2) {
      params = {
        isGuonei: 1,
        status: 1
      };
    }
    this.getAllDicts().then(() => {
      this.request({
        url: this.apiUrl + "/jiazheng/area/areaList",
        method: "get",
        params: params,
      }).then((res) => {
        console.log(res);
        res.data.map((v) => {
          v.text = v.areaName;
          v.id = v.areaId;

          if (this.area == 1) {
            if (typeof city[v.parentId] === "undefined") {
              city[v.parentId] = {};
            }
            if (v.parentId === 0) {
              // console.log(v.areaId,city[v.areaId],city,v)
              if (!city[v.areaId]) {
                city[v.areaId]={}
              }
              Object.assign(city[v.areaId], v);
            }
          } else if (this.area == 2) {
            console.log(v);
            if (v.parentId == 1) {
              city[v.areaId] = {};
            }
            if (v.parentId === 1) {
              Object.assign(city[v.areaId], v);
            }
          }

        });

        city = city.filter((v, i) => {
          v.children = that['jiazheng_service_type'];
          if (this.area == 1) {
            if (typeof city[i].parentId !== "undefined") {
              return v;
            }
          } else if (this.area == 2) {
            return v;
          }
        });

        // city = city.sort(function (a, b) {
        //   return a.orderNum - b.orderNum;
        // });

        // console.log(city);
        that.citys = city;
        if (this.$route.query.activeCityIndex) {
          this.activeCityIndex = this.$route.query.activeCityIndex
        }

        if (this.$route.query.activeSeviceTypeIndex) {
          this.activeSeviceTypeIndex = this.$route.query.activeSeviceTypeIndex
        }
      });
    })
  },
  methods: {
    setData(k, v) {
      console.log(k, v);
      this[k] = v;
    },
    load: function (pageNum) {
      // if (this.loading) {
      //   return false;
      // }
      if (pageNum && pageNum == 1) {
        this.queryParams.pageNum = pageNum
        this.list = [];
        this.loading = true;
      }
      if (this.refreshing) {
        this.refreshing = false;
      }
      // console.log(11111111111, this.loading)
      if (this.loading) {
        let params = this.queryParams
        if (this.area == 1) {
          Object.assign(params, {
            IsHaiwai: 1
          })
        } else if (this.area == 2) {
          Object.assign(params, {
            IsGuonei: 1
          })
        }
        this.request({
          url: this.apiUrl+'/ayi/list',
          method: "get",
          params: params,
        }).then(res => {
          console.log(Math.ceil(res.data.total / 10))
          pageNum = this.queryParams.pageNum
          if (Math.ceil(res.data.total / 10) > this.queryParams.pageNum) {
            this.queryParams.pageNum = this.queryParams.pageNum + 1;
            this.finished = false;
          } else {
            this.finished = true;
          }
          if (pageNum == 1) {
            this.list = res.data.list ? res.data.list : [];
          } else {
            this.list = res.data.list ? this.list.concat(res.data.list) : []
          }
          this.loading = false
          // console.log(res)
        })
      }

    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.load(1);
    },
    reset: function () {
      this.menuType = 1;
      this.queryParams.cityIds = "";
      this.queryParams.serviceTypeId = "";
      this.queryParams.pageNum = 1;
      this.activeCityIndex = ""
      this.activeSeviceTypeIndex = ""
      this.title = '全部';
      this.showMenu = false;
      // this.loading = true;
      // this.$router.push({ path: "/index", query: { activeCityIndex: '', activeSeviceTypeIndex: '' } })
    },
  },
};
</script>

<style lang="scss" scoped>
.van-nav-bar .van-icon {
  color: #fc0303;
}

.option {
  background: #666;
  color: #fff;
  text-align: center;
  font-size: 14px;
  line-height: 2.6rem
}

.people-list {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  // flex-flow: column wrap;
  // column-count: 2;
  // column-gap: 10px;
  margin-bottom:80px;
  padding: 0 10px;
}

.people-list .item {
  width: calc(50% - 10px - 8px);
  // box-sizing: border-box;
  // -webkit-column-break-inside: avoid;
  // break-inside: avoid;
  text-align: center;
  background: #ebedf0;
  box-shadow: 0 0 3px #999;
  border-radius: 3px;
  padding: 7px;
  margin-bottom: 10px;
}

.people-list .item img {
  width: 100%;
  height: auto;
}

.people-list .item p {
  font-size: 14px;
  color: #000;
  margin-bottom: 3px;
}

.people-list .name {
  text-align: center;
}

.people-list .main {
  font-weight: 600;
}

.people-list .detail {
  font-size: 13px !important;
  text-align: center;
  color: #666;
}

/deep/ .van-list__finished-text,
/deep/ .van-list__loading {
  width: 100%;
  text-align: center;
}

/deep/ .van-list__finished-text{
    position: absolute;
    text-align: center;
    bottom: -60px;
    width: 100px;
    left: calc(50% - 50px);
    display: block;
}

.home {
  min-height: 100vh;
  background-color: #efeff4;
  // padding-bottom: 160px;
}

.treeSelect {
  height: calc(100vh - 50px) !important;
}

// .van-tree-select__content {
//   width: 100%;
//   flex: 0;
// }
.van-button {
  margin-left: 8px;
  position: relative;
  top: -0.4rem;
}

.opt {
  padding: 10px 0 5px 0;
  border-bottom: 1px solid #ccc;
}

.van-button--small {
  height: 26px;
}

.logo {
  display: flex;

  .m-back {
    width: 30px;
    height: 30px;
    margin-top: 2px;
    border-radius: 50%;
    border: 5px solid #f7f8fa;
    color: #fff;
    display: inline-block;
    margin-right: 5px;

    /deep/ img {
      border-radius: 50%;
    }
  }

  .mulan {
    font-size: 20px;
    line-height: 46px;
  }
}
</style>